<template>
    <div>

    </div>
</template>
<script>
/* eslint-disable */
import api from "@/api/api";
export default{
    data(){
        return{

        }
    },
    created(){
        let loginInfo = this.$route.query
        if(loginInfo.code){
            api.getWeChatToken(loginInfo.code).then(res => {
                if(res.code == 200){
                    if(res.data.accessToken){
                        window.location.href(`http://10.80.221.195:3100/#/dashboard?token=${ res.data.accessToken }`)
                    }else{
                        window.location.href(`http://10.80.221.195:3100/#/login?type=2`)
                    }
                }
            })
        }
    }
}
</script>